.vh-75 {
  height: 75vh;
}

.vh-70 {
  height: 70vh;
}

.vh-40 {
  height: 40vh;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(70px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.fade-in {
  opacity: 0;
  position: relative;
  transition: all 0.5s ease;
  transform: translateY(70px);
}

.fade-in-active {
  opacity: 1;
  transform: translateY(0);
}

.feature .icon {
  font-size: 24px;
  margin-bottom: 10px;
}

.feature h3 {
  font-size: 18px;
  margin-bottom: 5px;
}

.feature p {
  font-size: 14px;
}

.animate__animated {
  animation-duration: 0.8s;
}

.animate__fadeInUp {
  animation-name: fadeInUp;
}

.accordion-button {
  --bs-accordion-btn-bg: rgb(51, 51, 51);
  --bs-accordion-active-bg: #384653;
  --bs-accordion-border-color: #ffffff00;
  --bs-accordion-btn-focus-border-color: #ffffff00;
  --bs-accordion-btn-focus-box-shadow: 0;
  --bs-accordion-active-color: #ffffff;
}

.puffAnimation {
  -webkit-animation: puff-in-center 0.6s cubic-bezier(0.39, 0.575, 0.565, 1)
    0.2s both;
  animation: puff-in-center 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) 0.2s both;
}

.puffHideAnimation {
  -webkit-animation: puffHide-in-center 0.6s cubic-bezier(0.39, 0.575, 0.565, 1)
    0.2s both;
  animation: puffHide-in-center 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) 0.2s
    both;
}

.imageScrollAnimation {
  -webkit-animation: scroll 20s linear infinite;
  animation: scroll 20s linear infinite;
}

@keyframes scroll {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  100% {
    -webkit-transform: translateX(calc(-100% - 10px));
    transform: translateX(calc(-100% - 10px));
  }
}

@-webkit-keyframes scroll {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  100% {
    -webkit-transform: translateX(calc(-100% - 10px));
    transform: translateX(calc(-100% - 10px));
  }
}

@-webkit-keyframes puff-in-center {
  0% {
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
    display: block;
  }
}

@keyframes puff-in-center {
  0% {
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
    display: block;
  }
}

@-webkit-keyframes puffHide-in-center {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }

  100% {
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
    opacity: 0;
    display: none;
  }
}

@keyframes puffHide-in-center {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }

  100% {
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
    opacity: 0;
    display: none;
  }
}

.giveawayThumbnail {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.blur {
  background-color: rgba(0, 0, 0, 0.6);
  box-shadow: 0px 0px 0.5em rgba(100, 100, 100, 0.7);
}

.no-focus-ring:focus {
  outline: none;
  box-shadow: none;
}
